<template>
  <div>
    <div class="first">
      <div class="container position-relative" style="z-index: 4;">
        <div class="row">
          <div class="col-12 col-lg-4 col-md-4">
            <div class="first__logo">
              <svg width="208" height="160" viewBox="0 0 208 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.88568H26.7671V10.3672L0 42.8054V3.88568Z"
                      fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 55.7375L26.7671 23.2684V107.651H0V55.7375Z"
                      fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M116.164 66.2005C113.924 72.2182 109.873 77.3918 104.57 81.0074C99.2678 84.6229 92.9747 86.5021 86.5596 86.3857C80.0494 86.266 73.7404 84.1072 68.5195 80.2128C63.1832 76.3781 59.2423 70.9058 57.2946 64.6264C55.46 58.47 55.6765 51.8834 57.9114 45.861C60.2953 39.6258 64.62 34.3235 70.2464 30.7375C75.6639 27.2912 82.0154 25.6098 88.4269 25.9249C94.8384 26.2399 100.995 28.5359 106.049 32.4968C108.396 34.3711 110.472 36.5629 112.217 39.0091C113.112 40.2171 113.906 41.4972 114.591 42.8363C114.807 43.2375 115.177 44.6264 115.547 44.8425C115.917 45.0585 116.133 44.8425 116.287 44.8425H143.116C141.908 39.3658 139.957 34.0802 137.318 29.1326C134.619 24.3332 131.232 19.9554 127.265 16.1388C123.287 12.3724 118.815 9.16406 113.974 6.60173C109.224 4.05743 104.12 2.23696 98.8329 1.20049C93.3456 0.0909239 87.7312 -0.251851 82.1498 0.181969C76.7529 0.531904 71.4401 1.69738 66.3917 3.63877C61.2953 5.52966 56.4891 8.127 52.1139 11.3548C47.7764 14.6055 43.9059 18.4377 40.6114 22.7437C37.3755 27.0538 34.7692 31.8031 32.8712 36.8486C30.8885 42.1039 29.7136 47.6297 29.3865 53.2375C29.149 58.7692 29.6888 64.3068 30.9901 69.6881C32.2807 74.8924 34.3607 79.8678 37.1576 84.4412C39.9503 89.0255 43.388 93.1834 47.3649 96.7869C55.3854 104.176 65.3257 109.15 76.0439 111.139C81.3813 112.125 86.8286 112.374 92.2337 111.879C97.8236 111.318 103.311 109.999 108.547 107.96C113.492 106.039 118.127 103.398 122.3 100.12C126.67 96.74 130.542 92.7606 133.803 88.2992C137.1 83.7439 139.718 78.7325 141.574 73.4227C142.358 71.0124 143.007 68.56 143.517 66.077H116.164"
                      fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M144.691 3.88568H171.335V82.6203H208.001L187.71 107.744H144.691V3.88568Z" fill="white"/>
                <path d="M17.7952 137.188H11.5968V159.626H6.44694V137.188H0.433594V133.176H17.7952V137.188Z"
                      fill="white"/>
                <path
                    d="M55.6634 147.96H47.5839V155.614H57.2669V159.626H42.4648V133.176H57.2361V137.188H47.5839V143.947H55.6634V147.96Z"
                    fill="white"/>
                <path
                    d="M90.9084 142.528H91.0009L94.7631 133.176H100.684L94.1155 146.293L101.146 159.626H94.9481L91.0625 150.059H90.97L87.0845 159.626H81.1328L87.8863 146.293L81.287 133.176H87.2078L90.9084 142.528Z"
                    fill="white"/>
                <path
                    d="M144.289 159.626H139.17V148.608H130.689V159.626H125.57V133.207H130.689V144.596H139.17V133.207H144.289V159.626Z"
                    fill="white"/>
                <path
                    d="M184.163 142.682C184.279 141.145 183.877 139.613 183.022 138.33C182.613 137.834 182.092 137.442 181.503 137.185C180.914 136.927 180.272 136.812 179.63 136.849C178.995 136.806 178.359 136.915 177.775 137.168C177.19 137.42 176.674 137.808 176.269 138.299C175.44 139.604 175.051 141.14 175.159 142.682V150.089C175.042 151.642 175.431 153.191 176.269 154.503C176.699 154.97 177.221 155.342 177.803 155.597C178.384 155.851 179.011 155.983 179.646 155.983C180.28 155.983 180.908 155.851 181.489 155.597C182.07 155.342 182.592 154.97 183.022 154.503C183.886 153.201 184.288 151.647 184.163 150.089V142.682ZM189.282 150.089C189.36 151.42 189.164 152.753 188.708 154.005C188.252 155.258 187.545 156.404 186.63 157.373C184.697 159.066 182.215 160 179.646 160C177.076 160 174.595 159.066 172.661 157.373C171.756 156.399 171.058 155.251 170.608 153.999C170.157 152.747 169.964 151.418 170.04 150.089V142.744C169.963 141.411 170.156 140.076 170.606 138.819C171.057 137.562 171.756 136.409 172.661 135.429C173.578 134.532 174.671 133.836 175.87 133.384C177.07 132.933 178.35 132.736 179.63 132.805C180.914 132.744 182.197 132.945 183.4 133.396C184.603 133.847 185.703 134.539 186.63 135.429C187.541 136.407 188.246 137.559 188.701 138.816C189.157 140.073 189.355 141.409 189.282 142.744V150.089Z"
                    fill="white"/>
              </svg>
            </div>
            <div class="first__title">
              ICL Техно — российский производитель вычислительной техники
            </div>
            <div class="first__desc">
              Особая экономическая зона «Иннополис»
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="second">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="second__title">Продукция</div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="second__item">
              Персональные компьютеры
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="second__item">
              Моноблоки
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="second__item">
              Ноутбуки и планшеты
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="second__item">
              Серверы и СХД
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="second__item">
              Тонкие клиенты
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="second__item">
              Информационные киоски
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="second__item">
              Интерактивные панели
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-12">
            <div class="second__title pt-4">Отраслевые решения</div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="second__item">
              Мобильные компьютерные классы ICLab и ICLab case
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="second__item">
              Телемедицинские комплексы ICLMed
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="second__item">
              Микродатацентры обработки данных ICL mDC RAY
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="second__item">
              Универсальные интерактивные системы InfoRay
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="second__item">
              Системы электронно-информационной очереди
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="second__item">
              Многофункциональные терминалы
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="second__item">
              Платежные станции PayRAY
            </div>
          </div>

        </div>

      </div>
    </div>
    <div class="third">
      <div class="container">
        <div class="row d-flex align-items-stretch">
          <div class="col-12">
            <div class="third__title">Icl Cognitive Основные факты об ICL Техно</div>
          </div>
          <div class="col-12 col-lg-3 col-md-6">
            <div class="third__item">
              <div class="third__image">
                <svg width="99" height="50" viewBox="0 0 99 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_868_1095)">
                    <path
                        d="M48.64 45.52H53.66V28.92H48.64V45.52ZM57.6 49.52H44.7V14.6H13.82V49.48H0V45.48H9.85999V10.66H48.64V24.98H57.6V49.52Z"
                        fill="#ED2026"/>
                    <path d="M35.8397 50.0001H31.8997V42.3001H26.6197V50.0001H22.6797V39.6801H35.8397V50.0001Z"
                          fill="#ED2026"/>
                    <path d="M41.0411 31.9399H17.6211V35.8799H41.0411V31.9399Z" fill="#B3B3B3"/>
                    <path d="M41.0411 25.38H17.6211V29.32H41.0411V25.38Z" fill="#B3B3B3"/>
                    <path d="M41.0411 18.84H17.6211V22.78H41.0411V18.84Z" fill="#B3B3B3"/>
                    <path d="M74.9194 38.08H63.8594V42.02H74.9194V38.08Z" fill="#B3B3B3"/>
                    <path d="M81.6194 7.14001H63.8594V11.08H81.6194V7.14001Z" fill="#B3B3B3"/>
                    <path d="M81.6194 14.14H63.8594V18.08H81.6194V14.14Z" fill="#B3B3B3"/>
                    <path
                        d="M87.7983 27.54C86.8837 27.536 85.9885 27.8036 85.2261 28.3088C84.4637 28.814 83.8685 29.5342 83.5157 30.378C83.163 31.2218 83.0686 32.1514 83.2446 33.0489C83.4206 33.9464 83.859 34.7715 84.5044 35.4196C85.1497 36.0678 85.9728 36.5098 86.8696 36.6897C87.7663 36.8695 88.6963 36.7792 89.5416 36.4301C90.387 36.0811 91.1097 35.4889 91.6182 34.7287C92.1268 33.9685 92.3983 33.0746 92.3983 32.16C92.3983 30.9381 91.9143 29.7661 91.0522 28.9002C90.1901 28.0344 89.0201 27.5453 87.7983 27.54ZM87.7983 40.6999C86.1044 40.6999 84.4486 40.1974 83.0405 39.2559C81.6324 38.3145 80.5353 36.9764 79.888 35.4111C79.2407 33.8458 79.0723 32.1236 79.4042 30.4626C79.7361 28.8016 80.5534 27.2764 81.7525 26.08C82.9517 24.8837 84.4788 24.07 86.1406 23.742C87.8024 23.414 89.5241 23.5863 91.0879 24.2373C92.6517 24.8882 93.9872 25.9886 94.9254 27.3989C95.8636 28.8092 96.3622 30.4661 96.3583 32.16C96.353 34.4268 95.4488 36.5989 93.844 38.1999C92.2393 39.8009 90.0651 40.6999 87.7983 40.6999Z"
                        fill="#ED2026"/>
                    <path d="M89.7603 22.24H85.8203V25.56H89.7603V22.24Z" fill="#ED2026"/>
                    <path d="M89.7603 38.92H85.8203V42.06H89.7603V38.92Z" fill="#ED2026"/>
                    <path d="M81.2189 30.1801H77.8789V34.1201H81.2189V30.1801Z" fill="#ED2026"/>
                    <path d="M98.3197 30.1801H95.1797V34.1201H98.3197V30.1801Z" fill="#ED2026"/>
                    <path d="M81.7382 35.4305L79.3906 37.7781L82.1766 40.564L84.5242 38.2164L81.7382 35.4305Z"
                          fill="#ED2026"/>
                    <path d="M93.3883 23.7583L91.168 25.9786L93.9539 28.7646L96.1742 26.5443L93.3883 23.7583Z"
                          fill="#ED2026"/>
                    <path d="M93.8407 35.4314L91.0547 38.2174L93.4023 40.5651L96.1883 37.7791L93.8407 35.4314Z"
                          fill="#ED2026"/>
                    <path d="M82.1649 23.7479L79.3789 26.5339L81.5992 28.7543L84.3852 25.9683L82.1649 23.7479Z"
                          fill="#ED2026"/>
                    <path
                        d="M79.6389 42.02V45.46H65.8189V3.94H79.6389V22.36H83.5789V0H61.8789V49.42H83.5789V42.02H79.6389Z"
                        fill="#B3B3B3"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_868_1095">
                      <rect width="98.34" height="50" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div class="third__subtitle">30 ЛЕТ</div>
              <div class="third__text">в индустрии компьютерных технологий</div>
            </div>
          </div>
          <div class="col-12 col-lg-3 col-md-6">
            <div class="third__item">
              <div class="third__image">
                <svg width="91" height="50" viewBox="0 0 91 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M87.3372 24.9237H74.4844V28.9855H87.3372V24.9237Z" fill="#ED2026"/>
                  <path d="M85.7161 32.3417H72.8633V36.4035H85.7161V32.3417Z" fill="#ED2026"/>
                  <path d="M84.0754 39.7788H71.2227V43.8406H84.0754V39.7788Z" fill="#ED2026"/>
                  <path
                      d="M60.5446 45.9381H79.4615C79.7843 45.9379 80.0975 45.8285 80.3503 45.6277C80.603 45.4269 80.7804 45.1466 80.8535 44.8322L86.1358 22.2349C86.1851 22.0262 86.1861 21.8089 86.1389 21.5997C86.0916 21.3905 85.9973 21.1949 85.8631 21.0276C85.7289 20.8603 85.5584 20.7258 85.3644 20.6343C85.1704 20.5428 84.9582 20.4968 84.7437 20.4996H67.0663L69.2212 11.1746V6.50262C69.2212 5.86031 68.966 5.24434 68.5119 4.79017C68.0577 4.33599 67.4417 4.08077 66.7994 4.08077H64.4538C63.9819 9.77633 61.6114 15.1485 57.7223 19.3363L55.3768 21.8535V42.5819L60.5446 45.9381ZM79.4615 49.9999H59.4386L51.2578 44.9275V20.347L54.6903 16.6476C58.3762 12.6774 60.4204 7.45783 60.4111 2.04044V0H66.7422C68.4668 0 70.1208 0.685126 71.3403 1.90461C72.5597 3.1241 73.2448 4.778 73.2448 6.50262V11.6323L72.1388 16.4187H84.6865C85.5149 16.419 86.3325 16.606 87.0787 16.9659C87.8248 17.3258 88.4802 17.8493 88.9962 18.4974C89.512 19.1428 89.8745 19.897 90.0562 20.7031C90.2379 21.5091 90.2341 22.3458 90.045 23.1502L84.8581 45.7475C84.5762 46.9644 83.888 48.0488 82.9069 48.8218C81.9258 49.5949 80.7105 50.0105 79.4615 49.9999Z"
                      fill="#B3B3B3"/>
                  <path d="M32.4364 45.8429H12.0703V49.6377H32.4364V45.8429Z" fill="#ED2026"/>
                  <path d="M3.79482 38.1197H40.7132V10.8885H3.79482V38.1197ZM44.508 41.9336H0V7.09375H44.508V41.9336Z"
                        fill="#B3B3B3"/>
                  <path d="M3.79482 31.1593H40.7132V10.8885H3.79482V31.1593ZM44.508 34.9732H0V7.09375H44.508V34.9732Z"
                        fill="#ED2026"/>
                </svg>

              </div>
              <div class="third__subtitle">ТОП 3</div>
              <div class="third__text">по производству ИТ-оборудования в России</div>
            </div>
          </div>
          <div class="col-12 col-lg-3 col-md-6">
            <div class="third__item">
              <div class="third__image">
                <svg width="75" height="50" viewBox="0 0 75 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M66.5949 40.0232H56.8828V43.4812H66.5949V40.0232Z" fill="#B3B3B3"/>
                  <path
                      d="M58.5986 46.3766H70.7098V12.1939H58.5655L58.5986 46.3766ZM74.2008 49.8345L55.1406 50V8.73596H74.2008V49.8345Z"
                      fill="#B3B3B3"/>
                  <path d="M72.485 14.8246H56.8828V18.2826H72.485V14.8246Z" fill="#B3B3B3"/>
                  <path d="M72.485 21.1947H56.8828V24.6527H72.485V21.1947Z" fill="#B3B3B3"/>
                  <path d="M44.541 33.3389H1.73828V36.7968H44.541V33.3389Z" fill="#B3B3B3"/>
                  <path
                      d="M23.5131 3.50759C22.2355 3.50432 20.9857 3.88046 19.922 4.58823C18.8583 5.296 18.0287 6.30361 17.5383 7.48336C17.0478 8.6631 16.9186 9.96181 17.1671 11.215C17.4155 12.4683 18.0305 13.6196 18.9339 14.523C19.8373 15.4264 20.9885 16.0413 22.2418 16.2897C23.495 16.5382 24.7938 16.409 25.9736 15.9186C27.1533 15.4282 28.1609 14.5985 28.8687 13.5349C29.5765 12.4712 29.9525 11.2214 29.9492 9.94375C29.9492 8.23678 29.2712 6.59968 28.0641 5.39267C26.8571 4.18567 25.2201 3.50759 23.5131 3.50759ZM23.5131 19.8544C21.5497 19.8544 19.6304 19.2722 17.9978 18.1814C16.3653 17.0905 15.093 15.5401 14.3416 13.7261C13.5902 11.9122 13.3936 9.91619 13.7767 7.9905C14.1597 6.06482 15.1052 4.29599 16.4935 2.90764C17.8819 1.5193 19.6508 0.573797 21.5764 0.190754C23.5021 -0.192289 25.4982 0.00429663 27.3121 0.755662C29.1261 1.50703 30.6765 2.77939 31.7673 4.41191C32.8581 6.04443 33.4403 7.96377 33.4403 9.92719C33.4403 12.56 32.3944 15.0851 30.5327 16.9468C28.671 18.8085 26.146 19.8544 23.5131 19.8544Z"
                      fill="#ED2026"/>
                  <path d="M25.248 18.3488H21.7734V22.2534H25.248V18.3488Z" fill="#ED2026"/>
                  <path d="M15.3238 8.22302H11.1875V11.681H15.3238V8.22302Z" fill="#ED2026"/>
                  <path d="M35.8353 8.22302H31.9141V11.681H35.8353V8.22302Z" fill="#ED2026"/>
                  <path d="M16.499 14.5289L13.5742 17.4537L16.0193 19.8989L18.9442 16.974L16.499 14.5289Z"
                        fill="#ED2026"/>
                  <path d="M30.9808 0.0456955L28.2188 2.80579L30.6747 5.26347L33.4367 2.50338L30.9808 0.0456955Z"
                        fill="#ED2026"/>
                  <path d="M30.5077 14.5219L28.0625 16.967L30.9873 19.8919L33.4325 17.4467L30.5077 14.5219Z"
                        fill="#ED2026"/>
                  <path d="M16.0116 0.0391024L13.5664 2.48425L16.3274 5.24527L18.7726 2.80012L16.0116 0.0391024Z"
                        fill="#ED2026"/>
                  <path
                      d="M44.5389 11.3336C43.7365 11.3303 42.9511 11.5652 42.2824 12.0087C41.6136 12.4521 41.0914 13.0839 40.7821 13.8244C40.4727 14.5648 40.3901 15.3804 40.5447 16.1678C40.6992 16.9552 41.0839 17.679 41.6502 18.2476C42.2164 18.8161 42.9387 19.2038 43.7254 19.3615C44.5122 19.5193 45.3281 19.44 46.0698 19.1337C46.8114 18.8274 47.4455 18.3078 47.8916 17.6409C48.3378 16.9739 48.576 16.1895 48.576 15.3871C48.576 14.3149 48.1512 13.2863 47.3945 12.5266C46.6379 11.7669 45.6111 11.3379 44.5389 11.3336ZM44.5389 22.9153C43.0533 22.9153 41.601 22.4747 40.3657 21.6493C39.1304 20.824 38.1677 19.6507 37.5991 18.2782C37.0306 16.9056 36.8818 15.3954 37.1717 13.9383C37.4615 12.4812 38.1769 11.1427 39.2274 10.0922C40.2779 9.04165 41.6164 8.32626 43.0735 8.03642C44.5306 7.74659 46.0409 7.89536 47.4135 8.46389C48.786 9.03242 49.9592 9.99519 50.7846 11.2305C51.6099 12.4657 52.0505 13.918 52.0505 15.4037C52.0418 17.3916 51.247 19.2955 49.8397 20.6997C48.4325 22.1039 46.5269 22.8944 44.5389 22.8987V22.9153Z"
                      fill="#B3B3B3"/>
                  <path d="M46.2792 6.68433H42.8047V9.61285H46.2792V6.68433Z" fill="#B3B3B3"/>
                  <path d="M46.2792 21.3269H42.8047V24.09H46.2792V21.3269Z" fill="#B3B3B3"/>
                  <path d="M38.7449 13.6499H35.8164V17.1079H38.7449V13.6499Z" fill="#B3B3B3"/>
                  <path d="M53.7709 13.6499H51.0078V17.1079H53.7709V13.6499Z" fill="#B3B3B3"/>
                  <path d="M39.2075 18.2575L37.1367 20.3282L39.5818 22.7734L41.6526 20.7026L39.2075 18.2575Z"
                        fill="#B3B3B3"/>
                  <path d="M49.4577 8.00714L47.5039 9.96094L49.9491 12.4061L51.9029 10.4523L49.4577 8.00714Z"
                        fill="#B3B3B3"/>
                  <path d="M49.8319 18.2624L47.3867 20.7075L49.4575 22.7783L51.9027 20.3332L49.8319 18.2624Z"
                        fill="#B3B3B3"/>
                  <path d="M39.5936 8.01786L37.1484 10.463L39.1022 12.4168L41.5474 9.97163L39.5936 8.01786Z"
                        fill="#B3B3B3"/>
                  <path d="M46.2607 43.0014H0V8.58716H7.79284V12.0451H3.47452V39.5269H42.8027V27.1014H46.2607V43.0014Z"
                        fill="#ED2026"/>
                  <path d="M32.2093 45.781H14.1914V49.239H32.2093V45.781Z" fill="#ED2026"/>
                  <path d="M25.248 41.2642H21.7734V47.5017H25.248V41.2642Z" fill="#ED2026"/>
                </svg>
              </div>
              <div class="third__subtitle">300к</div>
              <div class="third__text">изделий в год</div>
            </div>
          </div>
          <div class="col-12 col-lg-3 col-md-6">
            <div class="third__item">
              <div class="third__image">
                <svg width="77" height="50" viewBox="0 0 77 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M27.2611 50H0V29.8817C0 28.7105 0.23067 27.5509 0.678841 26.4689C1.12701 25.3869 1.78394 24.4038 2.61205 23.5757C3.44016 22.7476 4.42323 22.0908 5.50522 21.6426C6.5872 21.1944 7.74687 20.9637 8.91799 20.9637H25.9721C27.1432 20.9637 28.3029 21.1944 29.3849 21.6426C30.4668 22.0908 31.4499 22.7476 32.278 23.5757C33.1061 24.4038 33.7631 25.3869 34.2112 26.4689C34.6594 27.5509 34.8901 28.7105 34.8901 29.8817V34.1082H30.2409V29.8817C30.2409 28.7607 29.7956 27.6858 29.0029 26.8931C28.2103 26.1005 27.1353 25.6551 26.0143 25.6551H8.9603C7.83935 25.6551 6.76431 26.1005 5.97168 26.8931C5.17906 27.6858 4.73376 28.7607 4.73376 29.8817V45.3508H22.6965V41.6314H9.50971V37.0245H27.3457L27.2611 50Z"
                      fill="#ED2026"/>
                  <path
                      d="M17.436 4.67034C16.4111 4.67035 15.4093 4.97448 14.5574 5.54433C13.7055 6.11418 13.0419 6.92416 12.6507 7.87145C12.2594 8.81875 12.1581 9.86077 12.3596 10.8657C12.5611 11.8706 13.0563 12.7932 13.7825 13.5164C14.5087 14.2397 15.4333 14.731 16.439 14.9284C17.4447 15.1258 18.4864 15.0203 19.4321 14.6252C20.3778 14.2301 21.185 13.5631 21.7513 12.7089C22.3177 11.8546 22.6178 10.8516 22.6136 9.82672C22.608 8.45722 22.06 7.14572 21.0896 6.17932C20.1193 5.21291 18.8056 4.67033 17.436 4.67034ZM17.436 19.6535C15.4925 19.6535 13.5926 19.0771 11.9766 17.9973C10.3606 16.9175 9.10116 15.3828 8.3574 13.5872C7.61364 11.7916 7.41903 9.81583 7.79819 7.90963C8.17736 6.00344 9.11328 4.25253 10.4876 2.87824C11.8619 1.50395 13.6128 0.567967 15.519 0.188802C17.4252 -0.190364 19.401 0.00431528 21.1966 0.748075C22.9922 1.49183 24.5269 2.75124 25.6066 4.36723C26.6864 5.98322 27.2627 7.88318 27.2627 9.82672C27.2627 12.4329 26.2275 14.9323 24.3846 16.7752C22.5417 18.6181 20.0422 19.6535 17.436 19.6535Z"
                      fill="#ED2026"/>
                  <path
                      d="M76.6668 50H72.0176V29.8817C72.0176 28.7607 71.5723 27.6858 70.7797 26.8931C69.9871 26.1005 68.912 25.6551 67.7911 25.6551H50.737C49.6161 25.6551 48.541 26.1005 47.7484 26.8931C46.9558 27.6858 46.5105 28.7607 46.5105 29.8817V42.2232H34.972V45.3508H50.2721V35.4819H54.9424V50H30.2383V37.574H41.7556V29.8817C41.7612 27.5145 42.7055 25.2462 44.3813 23.5744C46.0571 21.9025 48.3276 20.9637 50.6948 20.9637H67.7488C70.114 20.9637 72.3824 21.9032 74.0548 23.5757C75.7272 25.2481 76.6668 27.5165 76.6668 29.8817V50Z"
                      fill="#ED2026"/>
                  <path
                      d="M59.2174 4.67034C58.1924 4.67035 57.1906 4.97448 56.3387 5.54433C55.4868 6.11418 54.8232 6.92416 54.4319 7.87145C54.0407 8.81875 53.9394 9.86077 54.1409 10.8657C54.3424 11.8706 54.8376 12.7932 55.5638 13.5164C56.29 14.2397 57.2145 14.731 58.2203 14.9284C59.226 15.1258 60.2677 15.0203 61.2134 14.6252C62.1591 14.2301 62.9662 13.5631 63.5326 12.7089C64.0989 11.8546 64.399 10.8516 64.3948 9.82672C64.3948 9.14779 64.2608 8.47548 64.0003 7.8485C63.7399 7.22151 63.3582 6.65222 62.8771 6.17313C62.3961 5.69404 61.8251 5.31466 61.1971 5.05677C60.569 4.79889 59.8963 4.66756 59.2174 4.67034ZM59.2174 19.6535C57.2738 19.6535 55.3739 19.0771 53.7579 17.9973C52.1419 16.9175 50.8824 15.3828 50.1387 13.5872C49.3949 11.7916 49.2003 9.81583 49.5794 7.90963C49.9586 6.00344 50.8945 4.25253 52.2688 2.87824C53.6431 1.50395 55.394 0.567967 57.3002 0.188802C59.2064 -0.190364 61.1823 0.00431528 62.9779 0.748075C64.7735 1.49183 66.3082 2.75124 67.388 4.36723C68.4677 5.98322 69.044 7.88318 69.044 9.82672C69.044 12.4329 68.0087 14.9323 66.1658 16.7752C64.323 18.6181 61.8236 19.6535 59.2174 19.6535Z"
                      fill="#ED2026"/>
                  <path d="M68.1297 35.4818H63.4805V50H68.1297V35.4818Z" fill="#ED2026"/>
                  <path d="M15.493 29.5435H9.08984V34.1715H15.493V29.5435Z" fill="#B3B3B3"/>
                </svg>

              </div>
              <div class="third__subtitle">300</div>
              <div class="third__text">партнеров в России</div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="fourth">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="fourth__text">
              <ul>
                <li>Кастомизация под индивидуальные требования заказчика</li>
                <li>Создание решений любой сложности</li>
                <li>Более 100 региональных сервисных центров в РФ</li>
                <li>Расширенное сервисное обслуживание NBD 24/7</li>
                <li>Гибкие финансовые условия</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-md-between align-items-md-end flex-column-reverse flex-md-row">
          <div class="col-12 col-md-6">
            <div class="fourth__link">
              <a href="mailto:sales@icl.kazan.ru">sales@icl.kazan.ru</a>
            </div>
            <div class="fourth__link">
              <a href="tel:+78002014848">+7 800 201 48 48</a>
            </div>
          </div>
          <div class="col-12 col-md-6 text-end">
            <div class="fourth__image">
              <img src="@/assets/img/partner-2/qr.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fourth__line"></div>
    <div class="fiveth">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <a href="https://drive.google.com/file/d/1LvoDhJrSmkTufx1Mdnsuv01bRXEFAYY7/view?usp=sharing" target="_blank" class="fiveth__link">
              Скачать полную презентацию
              <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.5 4.77359H17.975C17.8447 3.46724 17.2116 2.25436 16.1993 1.37171C15.1869 0.48906 13.868 0 12.5 0C11.132 0 9.81308 0.48906 8.80074 1.37171C7.7884 2.25436 7.15529 3.46724 7.025 4.77359H1.5C1.10218 4.77359 0.720644 4.92519 0.43934 5.19504C0.158035 5.46489 0 5.83089 0 6.21251V21.5611C0 21.9427 0.158035 22.3087 0.43934 22.5785C0.720644 22.8484 1.10218 23 1.5 23H23.5C23.8978 23 24.2794 22.8484 24.5607 22.5785C24.842 22.3087 25 21.9427 25 21.5611V6.21251C25 5.83089 24.842 5.46489 24.5607 5.19504C24.2794 4.92519 23.8978 4.77359 23.5 4.77359ZM12.5 0.936446C13.6066 0.938399 14.6739 1.33036 15.499 2.03782C16.324 2.74527 16.8493 3.71889 16.975 4.77359H8.025C8.15068 3.71889 8.67598 2.74527 9.50104 2.03782C10.3261 1.33036 11.3934 0.938399 12.5 0.936446ZM24 21.5611C24 21.6883 23.9473 21.8103 23.8536 21.9002C23.7598 21.9902 23.6326 22.0407 23.5 22.0407H1.5C1.36739 22.0407 1.24021 21.9902 1.14645 21.9002C1.05268 21.8103 1 21.6883 1 21.5611V6.21251C1 6.0853 1.05268 5.9633 1.14645 5.87335C1.24021 5.7834 1.36739 5.73287 1.5 5.73287H7V9.09037C7 9.21758 7.05268 9.33958 7.14645 9.42953C7.24021 9.51948 7.36739 9.57001 7.5 9.57001C7.63261 9.57001 7.75979 9.51948 7.85355 9.42953C7.94732 9.33958 8 9.21758 8 9.09037V5.73287H17V9.09037C17 9.21758 17.0527 9.33958 17.1464 9.42953C17.2402 9.51948 17.3674 9.57001 17.5 9.57001C17.6326 9.57001 17.7598 9.51948 17.8536 9.42953C17.9473 9.33958 18 9.21758 18 9.09037V5.73287H23.5C23.6326 5.73287 23.7598 5.7834 23.8536 5.87335C23.9473 5.9633 24 6.0853 24 6.21251V21.5611Z" fill="black"/>
              </svg>
            </a>
          </div>
          <div class="col-6 text-end">
            <a href="https://icl-techno.ru/" target="_blank" class="fiveth__link">
              Подробнее на сайте партнера
              <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 8L0.499999 15.7942L0.5 0.205771L14 8Z" fill="#52B5E0"/>
              </svg>

            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SecondPartner"
}
</script>

<style scoped lang="scss">

.fourth {
  padding: rem(30) 0;
  &__line {
    height: 2px;
    background: #E6E6E6;
    margin: rem(40) 0;
  }
  &__image {
    @include media(768) {
      text-align: center;
    }
    img {
      vertical-align: bottom;
      max-width: 100%;
    }
  }
  &__link {
    @include media(768) {
      text-align: center;
    }
    a {
      font-weight: 800;
      font-size: rem(32);
      color: #ED2026;
      text-decoration: none;
    }
  }
  &__text {
    ul {
      font-weight: 400;
      font-size: rem(24);
      color: #221E1F;
      @include media(768) {
        font-size: rem(21);
      }
    }
  }
}
.third {
  background-color: #E6E6E6;
  padding: rem(30) 0;

  &__title {
    font-weight: 400;
    font-size: rem(28);
    text-transform: uppercase;
    color: #221E1F;
    padding-bottom: rem(60);
  }

  &__item {
    text-align: center;
    @include media(992) {
      padding-bottom: rem(20);
    }
  }

  &__subtitle {
    padding-top: rem(20);
    font-weight: 800;
    font-size: rem(32);
    color: #ED2026;
  }

  &__text {
    max-width: 170px;
    margin: 0 auto;
    padding-top: rem(12);
    font-weight: 300;
    font-size: rem(18);
    color: #221E1F;
  }
}

.second {
  .col-lg-3 {
    margin-top: rem(20);
    margin-bottom: rem(20);
  }

  padding: rem(50) 0;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: rem(28);
    text-transform: uppercase;
    color: #221E1F;
    padding-bottom: rem(20);
  }

  &__item {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    height: 100%;
    background: #ED1C24;
    padding: rem(9) rem(9) rem(30);
    font-weight: 500;
    color: #fff;
    font-size: rem(16);

    &::before {
      content: '';
      position: absolute;
      right: -10px;
      bottom: -10px;
      width: 20px;
      height: 20px;
      background-color: #fff;
      transform: rotate(45deg);
    }
  }
}

.first {
  min-height: 700px;
  background-color: #ED1C24;
  position: relative;
  @include media(992) {
    min-height: auto;
    padding-bottom: rem(30);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(~@/assets/img/partner-2/background.png);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
  }

  &::after {
    content: '';
    height: 100%;
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-position: right bottom;
    right: 0;
    width: 50%;
    max-width: 840px;
    background-size: auto 100%;
    top: 0;
    z-index: 1;
    bottom: 0;
    background-image: url(~@/assets/img/partner-2/background2.png);
    @include media(992) {
      width: 40%;
      background-position: left bottom;
    }
    @include media(768) {
      background-image: none;
    }
  }

  &__logo {
    padding-top: rem(68);

    svg {
      max-width: 100%;
      height: auto;
    }
  }

  &__title {
    padding-top: rem(25);
    font-weight: bold;
    font-size: rem(32);
    color: #fff;
    padding-bottom: rem(15);
    max-width: 320px;
    @include media(768) {
      max-width: 100%;
    }
  }

  &__desc {
    font-size: rem(18);
    font-weight: 700;
    color: #fff;
  }
}
.fiveth {
  &__link {
    font-size: rem(18);
    color: #282938;
    display: inline-block;
    text-decoration: none;
  }
}
</style>
